// declare global {
//   interface Window {
//     swsm: any;
//   }
// }

export interface AppState {
  authReducer: AuthReducer;
  appReducer: AppReducer;
  externalLinksReducer: ExternalLinksReducer;
  locationReducer: LocationReducer;
  uiReducer: UiReducer;
  userReducer: UserReducer;
  preferencesReducer: PreferencesReducer;
  challengesReducer: ChallengesReducer;
  pageOverviewReducer: PageOverviewReducer;
  pageConsumptionReducer: PageReducer;
  pageFreeProductsReducer: PageFreeProductsReducer;
  pageChallengesReducer: PageChallengesReducer;
  pageActivityHistoryReducer: PageReducer;
  pageLeaderboardReducer: LeaderboardPageReducer;
  pageCompetitionsReducer: CompetitionsPageReducer;
  pageUnsubscribeReducer: UnsubscribeReducer;
  pageProductsFittedReducer: PageProductsFittedReducer;
  getInfoReducer: GetInfoReducer;
  supportTicketReducer: SupportTicketReducer;
  translationReducer: TranslationReducer;
  generalDataReducer: GeneralDataReducer;
  actionBannerReducer: ActionBannerReducer;
  discountLinkReducer: DiscountLinkReducer;
  filterReducer: FilterReducer;
  videoLibraryReducer: VideoLibraryReducer;
  notificationsReducer: NotificationsReducer;
  availableRewardsReducer: AvailableRewardsReducer;
  optRewardsReducer: OptRewardsReducer;
  deleteAccountReducer: DeleteAccountReducer;
  promoCodeReducer: PromoCodeReducer;
  contactFormReducer: ContactFormReducer;
  marketplaceReducer: MarketplaceReducer;
  searchReducer: SearchReducer;
  companyNameReducer: CompanyNameReducer;
  adAnalyticsReducer: AdAnalyticsReducer;
  suggestiveSearchReducer: SuggestiveSearchReducer;
  reportingFiguresReducer: ReportingFiguresReducer;
  unifiedLabelReducer: UnifiedLabelReducer;
  reviewReducer: ReviewReducer;
}

export interface FilterReducer {
  filterOptions: {
    [id: string]: {
      options: Object;
      filterBy: string;
    };
  };
}

export interface GeneralDataReducer {
  data: null | any;
  status: Status;
}

export interface RadioControlProps {
  answerKey: string;
  currentStep: number;
  options: Array<any>;
  required: boolean;
  goToNext: Function;
  key: string;
  updateAnswerKey: Function;
  value: string;
  text: string;
}

export interface Challenge {
  completed: boolean;
  id: number;
  tag: string;
  icon: string;
  type: 'easy' | 'medium' | 'hard' | 'pledge' | 'product';
  title: string;
  imgUrl: string;
  coins: number;
  pageRequiredClicks: number;
  pledgeClickCount: number;
  userSubmittedTip: boolean;
  water_saving: number;
  saving: {
    water: number;
    energy: number;
  };
  hasTips: boolean;
  tipPoints: number;
  buttonText?: string;
  buttonPath?: string;
  extendable: boolean;
  description: string;
  socialSharable: boolean;
  socialShareText: string;
  /** Value In minutes */
  duration: number;
  activityLogText: string;
  socialSharePoints: number;
}

export interface UiReducer {
  appWidth: number;
}

export interface SearchReducer {
  status: Status;
  data: null | any;
}
export interface ActionBannerReducer {
  status: Status;
  data:
    | []
    | {
        id: number;
        title: string;
        message: string;
        showDismissButton: boolean;
        buttons: Array<{
          url: string;
          text: string;
          callDismiss: boolean;
        }>;
      };
}

export interface DiscountLinkReducer {
  status: Status;
  data: null | {
    url: string;
  };
}

export interface Status {
  loading: boolean;
  error: boolean | string | null;
  done: boolean;
}

export interface LocationReducer {
  status: Status;
  data: null | any;
}

export interface AuthReducer {
  resetRequest: {
    status: Status;
  };
  resetUpdate: {
    status: Status;
  };
}

export interface UnsubscribeReducer {
  status: Status;
}

export interface PageReducer {
  status: Status;
  data: null | any;
}

export interface VideoLibraryReducer {
  status: Status;
  data: null | any;
}

export interface NotificationsReducer {
  getNotifications: {
    status: Status;
    data: null | any;
  };
  readNotification: {
    status: Status;
    data: null | any;
  };
}

export interface LeaderboardPageReducer extends Omit<PageReducer, 'data'> {
  donateStatus: Status;
  data: null | {
    contentBox?: {
      content: string;
      title: string;
    };
    charityBox: Array<{
      id: number;
      title: string;
      description: string;
      coins: number;
      image: string;
    }>;
  };
}
export interface CompetitionsPageReducer extends Omit<PageReducer, 'data'> {
  donateStatus: Status;
  data: null | {
    competitions: {
      id: number;
      name: string;
      description: string;
      url: string;
      logo: string;
      view: string;
    };
    leaderBoard: Array<{
      id: number;
      name: string;
      participants: number;
      litres: number;
      position: number;
    }>;
    name: string;
    description: string;
    teamSupported: null | number;
  };
}

export interface PreferencesReducer {
  userData: {
    status: Status;
    data: null | {
      id: number;
      water_client: string;
      water_client_slug: string;
      water_client_id: number;
      email: string;
      mobile: string;
      first_name: string | '';
      last_name: string | '';
      postcode: string;
      agree_to_messaging: boolean;
      agree_to_sms: boolean;
      agree_to_terms: boolean;
      agree_to_water_company: boolean | null;
    };
  };
  updateData: {
    status: Status;
  };
}

export interface GetInfoReducer {
  status: Status;
  data: null | any;
}

export interface ExternalLinksReducer {
  status: Status;
  data: null | any;
}

export interface AvailableRewardsReducer {
  status: Status;
  data: null | any;
}
export interface OptRewardsReducer {
  optIn: {
    status: Status;
    data: null | any;
  };
}

export interface PageTipsReducer {
  status: Status;
  data: null | any;
  current: null | Challenge;
}

export interface PageFreeProductsReducer {
  basicInfo: {
    status: Status;
    data: null | any;
  };
  order: {
    status: Status;
    data: null | any;
  };
  preCheckout: {
    status: Status;
    creditAudit: null | any;
    basketRejected: boolean;
  };
  details: {
    email: string;
    title: string;
    firstName: string;
    lastName: string;
    phone: string;
    address: string;
    toc: boolean;
    marketing: boolean;
  };
  addresses: {
    status: Status;
    data: Array<{
      id: number;
      displayname: string;
      buildname: string;
      delpts: string;
      organisa: string;
      number: string;
      street: string;
      town: string;
      postcode: string;
      address1: string;
      address2: string;
      address3: string;
      address4: string;
      county: string;
    }>;
  };
  products: {
    status: Status;
    data: null | any;
    groups: Array<{
      name: string;
      limit: number;
      limitRule: string;
      products: Array<number>;
    }>;
  };
  basket: any;
}

export interface PageProductsFittedReducer {
  getData: {
    status: Status;
    data: null | {
      products: null | Array<{
        id: number;
        title: string;
        water_use_per_minute: number;
        litres_saved: number;
        image: string;
      }>;
      bookingId: string;
    };
  };
  setData: {
    status: Status;
  };
}

export interface PageChallengesReducer {
  status: Status;
  data: null | any;
}

export interface SupportTicketReducer {
  status: Status;
  data: null | any;
}

export interface TranslationReducer {
  status: Status;
  availableLanguages: null | any;
  currentLanguage: null | any;
}

export interface ChallengesReducer {
  status: Status;
  bonusChallengesStatus: Status;
  userChallenges: {
    [id: string]: {
      challenge: Challenge;
      completed: boolean;
      pledgeClickCount: number;
      pledgeLastClicked: string;
      id: number;
      tag: string;
      buttonText: string;
      category: string;
      type: string;
      internalType: string;
    };
  };
  active: {
    [id: string]: {
      challenge: Challenge;
      completed: boolean;
      pledgeClickCount: number;
      pledgeLastClicked: string;
      id: number;
      tag: string;
      buttonText: string;
      category: string;
      type: string;
      internalType: string;
    };
  };
  completedPledges: {
    [id: string]: {
      challenge: Challenge;
      completed: boolean;
      pledgeClickCount: number;
      pledgeLastClicked: string;
      id: number;
      tag: string;
      category: string;
      type: string;
      internalType: string;
    };
  };
  bonusChallenges: {
    [id: string]: {
      title: string;
      description: string;
      icon: string;
      tag: string;
      image: string;
      buttonText: string;
      internalType: string;
      buttonPath: string;
    };
  };
  featuredChallenge: {
    [id: string]: {
      title: string;
      tag: string;
      description: string;
      icon: string;
      image: string;
      buttonText: string;
      internalType: string;
    };
  };
  atLoadingState: Array<string | undefined>;
  smartMeterSignup: any;
  leakFixSignup: any;
  noEasyWinsAvailable: Boolean;
  challengeList: {};
}

export interface SharedPage {
  location: {
    pathname: string;
    search: string;
    hash: string;
  };
  appWidth: number;
  getPage: Function;
  app: AppReducer;
}

export interface PageOverviewReducer {
  status: Status;
  data: null | any;
}

export interface ContactFormReducer {
  setContact: {
    status: Status;
    data: null | any;
  };
  setContactEmail: {
    status: Status;
    data: null | any;
  };
}

export interface DeleteAccountReducer {
  status: Status;
  data: null | any;
}

export interface PromoCodeReducer {
  status: Status;
  data: null | any;
}

export interface MarketplaceReducer {
  status: Status;
  data: null | any;
}

export interface BasePage {
  location: {
    pathname: string;
    search: string;
    hash: string;
  };
  match: {
    path: string;
    url: string;
    isExact: boolean;
    params: any;
  };
  appWidth: number;
  getPage: Function;
  page: PageReducer;
  app: AppReducer;
}

export interface UserReducer {
  status: Status;
  data: null | {
    accessToken: string;
    refreshToken: string;
    id: number;
    email: string;
    rewardsProgram?: boolean;
    showTutorial?: boolean;
  };
}

export interface IconProps {
  color?: string;
  size?: string | number;
  icon: string;
}

export interface Routes {
  [name: string]: {
    template: any;
    authRequired?: boolean;
  };
}

export interface DeleteAccountReducer {
  status: Status;
  data: null | any;
}

export interface AppReducer {
  lockApp: boolean;
  config: AppConfig;
  generalData: GeneralData;
  clientConfig: {
    status: Status;
    data: null | any;
  };
  locationConfig: {
    status: Status;
    data: null | any;
  };
  userData: {
    status: Status;
    data: null | {
      id: number;
      water_client: string;
      water_client_slug: string;
      water_client_id: number;
      email: string;
      mobile: string;
      first_name: string | '';
      last_name: string | '';
      postcode: string | '';
      agree_to_messaging: boolean;
      agree_to_sms: boolean;
      agree_to_terms: boolean;
      agree_to_water_company: boolean | null;
      is_rewards_enabled: boolean | null;
      rewards_enabled_at: boolean | null;
    };
  };
}

interface NavElement {
  location: 'footer' | 'main';
  path: string;
  name: string;
  title: string;
  description?: string;
}

export interface AppConfig {
  status: Status;
  data: null | {
    socialMedia?: {
      facebook?: string;
      instagram?: string;
      twitter?: string;
      youtube?: string;
    };
    navElements: Array<NavElement>;
  };
  dataNormalized?: null | {
    entities: {
      pages: {
        [key: string]: NavElement | undefined;
      };
    };
    result: Array<'string'>;
  };
}

export interface GeneralData {
  status: {
    loadingGeneral: boolean;
    errorGeneral: boolean | string | null;
    doneGeneral: boolean;
  };
  data: null | {
    coins?: number;
    waterUsage?: number;
    energyUsage?: number;
    carbonUsage?: number;
    has_home_audit?: boolean;
    waterClientSlug?: string;
    totalWaterSaved?: number;
    competitionId?: number;
    charityBoardId?: number;
    completedChallenges?: number;
  };
}

export interface ChartData {
  chartTheme: string;
  links?: {
    icon: string;
    text: string;
    link: string;
    isExternalLink: boolean;
  }[];
  dataSets: {
    cost?: {
      xLabel: string;
      yLabel: string;
      showOriginalValue: boolean;
      data: {
        value: number;
        savingValue: number;
        originalValue: number;
        label: string;
        difference: number;
      }[];
    };
    energy?: {
      xLabel: string;
      yLabel: string;
      showOriginalValue: boolean;
      data: {
        value: number;
        savingValue: number;
        originalValue: number;
        label: string;
        difference: number;
      }[];
    };
    use?: {
      xLabel: string;
      yLabel: string;
      showOriginalValue: boolean;
      data: {
        savingValue: number;
        value: number;
        originalValue: number;
        label: string;
        difference: number;
      }[];
    };
    carbon?: {
      xLabel: string;
      yLabel: string;
      showOriginalValue: boolean;
      data: {
        value: number;
        savingValue: number;
        originalValue: number;
        label: string;
        difference: number;
      }[];
    };
  };
}

export interface ButtonsControlProps {
  answerKey: string;
  helpTitle: string;
  color_theme: string;
  value: string;
  options: Array<any>;
  goToNext: Function;
  goToPrev: Function;
  modalShow: Function;
  updateAnswerKey: Function;
  currentStep: number;
}

export interface CheckboxControlProps {
  answerKey: string;
  currentStep: number;
  validateRequiredKeys: Function;
  required: boolean;
  key: string;
  updateAnswerKey: Function;
  value: string;
  text: string;
  desc: string;
}

export interface CounterControlProps {
  currentStep: number;
  answerKey: string;
  validateRequiredKeys: Function;
  value: any;
  defaultValue: string;
  max: string | number;
  min: number;
  updateAnswerKey: Function;
  help_image_url?: string;
  desc?: string;
  answers: Object;
  controls: Array<Object>;
  maxInGroup?: boolean;
  grouped?:
    | boolean
    | {
        firstInGroup: boolean;
        lastInGroup: boolean;
        inGroup: boolean;
      };
}

export interface InputControlProps {
  currentStep: number;
  answerKey: string;
  validateRequiredKeys: Function;
  value: string;
  max: string | number;
  min: number;
  inputType: string;
  required: boolean;
  placeholder: string;
  updateAnswerKey: Function;
  setNavNextVisibility: Function;
}

export interface SelectControlProps {
  answerKey: string;
  currentStep: number;
  defaultValue: string;
  key: string;
  options: Array<{
    text: string;
    value: string;
  }>;
  size: string;
  updateAnswerKey: Function;
  validateRequiredKeys: Function;
  value: string;
  setNavNextVisibility: Function;
  placeholder: string;
}
export interface MultiSelectDropdownControlProps {
  answerKey: string;
  dropdownAnswerKey: string;
  currentStep: number;
  key: string;
  options: Array<{
    label: string;
    value: string;
    option_text: string;
  }>;
  updateAnswerKey: Function;
  setDropdownKey: Function;
  validateRequiredKeys: Function;
  value: string;
  dropdownValue: any;
  setNavNextVisibility: Function;
  placeholder?: string;
  required: boolean;
  text: string;
  dropdown_text: string;
  desc: string;
}

export interface SliderControlProps {
  answerKey: string;
  currentStep: number;
  defaultValue: string;
  help_image_url: string;
  key: string;
  max: string | number;
  min: number;
  required: boolean;
  setNavNextVisibility: Function;
  sliderIndicatorText: string;
  updateAnswerKey: Function;
  value: string;
  validateRequiredKeys: Function;
}

export interface QuestionProps {
  conditionalKeysVisibility: any;
  currentStep: number;

  setQuestionHeight(height: number): number;
  containerHeight: number;
  updateAnswerKey: Function;
  setDropdownKey: Function;
  answers: Array<string>;
  dropdownValue: Array<string>;
  data: {
    controls;
    color_theme;
    id;
    title;
    icon;
    text;
  };
  modalShow: Function;
  validateRequiredKeys: Function;
  setNavNextVisibility: Function;
  navNextVisible: Function;
  goToNext: Function;
  goToPrev: Function;
  questiontype: string;
  postProgress: Function;
  totalSteps: number;
  qaProgress: object;
  status: Status;
}

export interface ChapterProps {
  currentStep: number;
  setQuestionHeight(height: number): number;
  containerHeight: number;
  data: {
    controls;
    color_theme;
    show_progress;
    id;
    title;
    icon;
    text;
    icon_tiles;
    note;
  };
  goToNext: Function;
  goToPrev: Function;
  app: AppReducer;
  appWidth: number;
}

export interface ButtonProps {
  style?: any;
  href?: string;
  disabled?: boolean;
  classes?: Array<string>;
  id?: string;
  colorSecondary?: string;
  colorPrimary?: string;
  active?: boolean;
  appearance?: 'DEFAULT' | 'OUTLINED' | 'TRANSPARENT';
  onClick?: (e: any) => void;
  type?: 'button' | 'submit';
}

export interface ModalShow {
  type: string;
  modalType: string;
  modalProps: Object;
}

export interface ModalState {
  modalType: string | undefined;
  modalProps: Object;
}

export enum ModalActionTypes {
  MODAL_SHOW = 'MODAL_SHOW',
  MODAL_HIDE = 'MODAL_HIDE',
}

export interface CompanyNameReducer {
  status: Status;
  data: null | any;
}

export interface AdAnalyticsReducer {
  status: Status;
  data: null | any;
}

export interface SuggestiveSearchReducer {
  status: Status;
  data: null | any;
}

export interface ReportingFiguresReducer {
  getRegistration: {
    status: Status;
    data: null | any;
  };
  getWaterSaving: {
    status: Status;
    data: null | any;
  };
  getCarbonSaving: {
    status: Status;
    data: null | any;
  };
  getMoneySaving: {
    status: Status;
    data: null | any;
  };
}

export interface UnifiedLabelReducer {
  status: Status;
  data: null | any;
}

export interface ReviewReducer {
  getReview: {
    status: Status;
    data: null | any;
  };
  setReview: {
    status: Status;
    data: null | any;
  };
}
