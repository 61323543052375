/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Typography from '../Typography';
import { GetInfoReducer } from '../../interfaces';
import * as _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { Col, Row } from 'react-styled-flexboxgrid';
import { getVariable } from '../../helpers';

interface FooterInfoProps {
  getInfo: Function;
  page: GetInfoReducer;
  slug?: string;
}

const FooterInfo: React.FC<FooterInfoProps> = props => {
  const { getInfo, page } = props;
  const [t] = useTranslation('common');
  const swsmClient = getVariable('client', 'waterClient');
  React.useEffect(() => {
    if (!page.status.done) {
      getInfo();
    }
  }, [getInfo, page.status.done]);

  const waterHeatedWithCoal = _.get(props, 'page.data.data.WATER_HEATED_WITH_COAL_KWH');
  const waterHeatedWithElectric = _.get(props, 'page.data.data.WATER_HEATED_WITH_ELECTRIC_KWH');
  const waterHeatedWithGas = _.get(props, 'page.data.data.WATER_HEATED_WITH_GAS_KWH');
  const waterHeatedWithOil = _.get(props, 'page.data.data.WATER_HEATED_WITH_OIL_KWH');
  const waterHeatedWithRenewable = _.get(props, 'page.data.data.WATER_HEATED_WITH_RENEWABLE_KWH');
  const averageWaterAndSewageChargePerQuarter = _.get(
    props,
    'page.data.data.AVERAGE_WATER_AND_SEWAGE_CHARGE_PER_QUARTER',
  );
  const waterUtility = _.get(props, 'page.data.data.WATER_UTITLITY');
  const basedOn = _.get(props, 'page.data.data.BASED_ON');
  const waterCostPerKilolitre = _.get(props, 'page.data.data.WATER_COST_PER_KILOLITRE');

  const link = 'http://www.energysavingtrust.org.uk/about-us/our-calculations';
  const linkAus =
    'https://www.compareenergy.com.au/knowledge-centre/electricity/average-electricity-costs-in-australia';

  const text_key = t('portal.home.household_savings');

  return (
    <Row>
      <Col xs={12}>
        {swsmClient === 'aus' ? null : (
          <Typography
            size="p"
            style={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px', marginBottom: '0' }}
            text={text_key}
          />
        )}
        <p
          style={{
            fontSize: '12px',
            lineHeight: '18px',
            marginBottom: '0',
            color: 'rgb(11, 38, 65)',
            fontWeight: 'normal',
          }}
        >
          <Trans
            i18nKey={swsmClient === 'aus' ? 'portal.footer.footer_disclaimer_aus' : 'portal.footer.footer_disclaimer'}
            values={{
              waterHeadedWithGas: `${waterHeatedWithGas}`,
              waterHeatedWithElectric: `${waterHeatedWithElectric}`,
              waterHeadedWithCoal: `${waterHeatedWithCoal}`,
              waterHeadedWithElectric: `${waterHeatedWithElectric}`,
              waterHeatedWithOil: `${waterHeatedWithOil}`,
              waterHeadedWithRenewable: `${waterHeatedWithRenewable}`,
              averageWaterAndSewageChargePerQuarter: `${averageWaterAndSewageChargePerQuarter}`,
              waterUtility: `${waterUtility}`,
              basedOn: `${basedOn}`,
              waterCostPerKilolitre: `${waterCostPerKilolitre}`,
            }}
            components={{
              url: (
                <a
                  style={{
                    textDecoration: 'underline',
                    fontSize: '12px',
                    lineHeight: '18px',
                    marginBottom: '0',
                    color: 'rgb(11, 38, 65)',
                  }}
                  href={swsmClient === 'aus' ? linkAus : link}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </p>
      </Col>
    </Row>
  );
};

export default FooterInfo;
