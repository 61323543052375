import styled from 'styled-components';

const Wraper = styled.div`
  position: relative;
  width: 100%;
  height: 8px;
  margin: 0 auto 0;
  background-color: #d7f1ff;
  overflow: hidden;
  border-radius: 4px;
`;

const Bar = styled.div`
  transition: width 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  height: 8px;
  border-radius: 4px;
  width: ${props => props.progress}%;
  background-color: #25b3eb;
  overflow: hidden;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex: 0 0 20px;
  }
`;

const Text = styled.div`
  width: auto;
  z-index: 2;
  color: #fff;
  position: absolute;
  font-size: 14px;
  left: 110px;
`;

export default { Wraper, Text, Bar };
