import React from 'react';
import Styled from './MultiSelectDropdownControl.style';
import { MultiSelectDropdownControlProps } from '../../../interfaces';
import { Checkbox } from '@savewatersavemoney/swsm-ui';
import { useTranslation } from 'react-i18next';
const MultiSelectDropdownControl: React.FC<MultiSelectDropdownControlProps> = props => {
  const {
    answerKey,
    value,
    desc,
    text,
    dropdown_text,
    required,
    setNavNextVisibility,
    validateRequiredKeys,
    options,
    dropdownValue,
    setDropdownKey,
    dropdownAnswerKey,
    updateAnswerKey = () => void 0,
  } = props;
  // const currentValue = localStorage.getItem();

  const [isVisible, setVisible] = React.useState(!!value);
  const [isValue, setValue] = React.useState(
    dropdownValue[dropdownAnswerKey] ? `${dropdownValue[dropdownAnswerKey]}` : '',
  );
  const [t] = useTranslation('common');

  React.useEffect(() => {
    setNavNextVisibility(true);
  }, [setNavNextVisibility]);

  const handleUpdate = event => {
    updateAnswerKey(answerKey, event.target.checked);

    if (event.target.checked) {
      setNavNextVisibility(false);
    }
    else {
      setNavNextVisibility(true);
      setValue('');
    }
    if (required) {
      validateRequiredKeys();
    }
  };

  const handleChange = event => {
    if (event.target) {
      updateAnswerKey(dropdownAnswerKey, event.target.value);
      setDropdownKey(dropdownAnswerKey, event.target.value);
      setValue(event.target.value);
      setNavNextVisibility(true);
    }
    else {
      setValue('');
      setNavNextVisibility(false);
    }
  };

  return (
    <Styled.CheckboxControl>
      <div className="controls">
        <Checkbox
          style={{ marginBottom: '16px' }}
          boxSize={35}
          type="checkbox"
          name={`GWF-${answerKey}`}
          id={`GWF-${answerKey}`}
          checked={!!value}
          onChange={event => {
            handleUpdate(event);
            setVisible(!isVisible);
          }}
        >
          {text ? (
            <div
              style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', height: '39px', alignItems: 'center' }}
            >
              {text}
            </div>
          ) : (
            ''
          )}
          {desc === null ? (
            <div
              style={{
                fontSize: text ? '14px' : '18px',
                paddingTop: text ? 0 : '7px',
              }}
            >
              {desc}
            </div>
          ) : null}
        </Checkbox>
        {isVisible && (
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <Styled.Label htmlFor="this">{dropdown_text}</Styled.Label>
            <Styled.SelectOption id="this" value={isValue} onChange={handleChange} placeholder={'Select...'}>
              <option value="" disabled>
                Select value...
              </option>
              {options.map(el => {
                return (
                  <option key={el.value} value={el.value}>
                    {t(el.option_text)}
                  </option>
                );
              })}
            </Styled.SelectOption>
          </div>
        )}
      </div>
    </Styled.CheckboxControl>
  );
};

export default MultiSelectDropdownControl;
