/* eslint-disable prefer-destructuring */
import React from 'react';
import StepNav from '../../StepNav';
import {
  CounterControl,
  SliderControl,
  InputControl,
  SicCodesControl,
  SelectControl,
  MultiSelectDropdownControl,
  RadioControl,
  CheckboxControl,
  MultiCounterControl,
} from '../../InputControls';
import { QuestionProps } from '../../../interfaces';
import Styled from './Question.style';
import { Icon } from '@savewatersavemoney/swsm-ui';
import { addGroup } from '../../../helpers';
import { seConstants } from '../../../constants';
import Typography from '../../Typography';
import i18next from 'i18next';

class Question extends React.Component<QuestionProps, any> {
  private questionRef = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
    };
    this.handleKeyUpdate = this.handleKeyUpdate.bind(this);
  }

  handleKeyUpdate(newTotal: number) {
    if (this.state.total !== newTotal) {
      this.setState({ total: newTotal });
    }
  }

  componentDidMount() {
    const { qaProgress, currentStep, postProgress } = this.props;
    if (this.questionRef.current && this.props.containerHeight !== this.questionRef.current.clientHeight) {
      this.updateQuestionHeight();
    }
    if (currentStep === 1) {
      return postProgress(qaProgress, seConstants.START_TRACKING);
    }
  }

  componentDidUpdate() {
    if (this.questionRef.current && this.props.containerHeight !== this.questionRef.current.clientHeight) {
      this.updateQuestionHeight();
    }
  }

  updateQuestionHeight() {
    if (this.questionRef.current) {
      this.props.setQuestionHeight(this.questionRef.current.clientHeight);
    }
  }

  trackProgress = () => {
    const { qaProgress, postProgress } = this.props;
    return postProgress(qaProgress, seConstants.TRACKING);
  };

  handleNext = () => {
    const { controls } = this.props.data;
    if (controls) {
      controls.forEach(control => {
        const { key, defaultValue } = control;
        // IF slider || counter || input -> submit default values when go NEXT
        if (defaultValue) {
          if (!(key in this.props.answers)) {
            // update as Number type
            this.props.updateAnswerKey(key, defaultValue);
          }
        }
      });
    }
    this.trackProgress();
    this.props.goToNext();
  };

  handlePrev = e => {
    e && e.preventDefault();
    this.props.goToPrev();
  };

  controllsHandler = () => {
    const { controls } = this.props.data;
    const {
      setNavNextVisibility,
      conditionalKeysVisibility,
      updateAnswerKey,
      dropdownValue,
      setDropdownKey,
      answers,
      validateRequiredKeys,
      currentStep,
    } = this.props;
    if (controls) {
      return addGroup(controls).map(control => {
        const {
          type,
          key,
          min,
          grouped,
          max,
          inputType,
          dropdown_text,
          options,
          text,
          desc,
          size,
          defaultValue,
          required,
          help_image_url,
          placeholder,
        } = control;

        const sharedProps = {
          answerKey: key,
          key,
          updateAnswerKey,
          currentStep,
          value: answers[key],
        };

        if (
          conditionalKeysVisibility &&
          conditionalKeysVisibility.hasOwnProperty(key) &&
          conditionalKeysVisibility[key] === false
        ) {
          return null;
        }

        switch (type) {
          case 'counter':
            return (
              <CounterControl
                {...sharedProps}
                answers={answers}
                controls={controls}
                defaultValue={defaultValue}
                desc={desc}
                help_image_url={help_image_url}
                max={max}
                min={min}
                validateRequiredKeys={validateRequiredKeys}
                grouped={grouped}
              />
            );
          case 'multi-counter':
            return (
              <MultiCounterControl
                {...sharedProps}
                key={key}
                maxKey={max}
                value={answers}
                updateAnswerKey={updateAnswerKey}
                options={control.options}
                controls={controls}
                validateRequiredKeys={validateRequiredKeys}
                onTotalUpdate={this.handleKeyUpdate}
              />
            );
          case 'buttons':
            return (
              <RadioControl
                {...sharedProps}
                required={required}
                goToNext={this.handleNext}
                updateAnswerKey={updateAnswerKey}
                text={i18next.t(text)}
                options={options}
              />
            );
          case 'input':
            return (
              <InputControl
                {...sharedProps}
                key={key}
                max={max}
                min={min}
                inputType={inputType}
                required={required}
                placeholder={placeholder}
                setNavNextVisibility={setNavNextVisibility}
                validateRequiredKeys={validateRequiredKeys}
              />
            );
          case 'sic_codes':
            return (
              <SicCodesControl
                {...sharedProps}
                max={max}
                min={min}
                size={size}
                options={options}
                setNavNextVisibility={setNavNextVisibility}
              />
            );
          case 'select':
            return (
              <SelectControl
                {...sharedProps}
                defaultValue={defaultValue}
                options={options}
                setNavNextVisibility={setNavNextVisibility}
                size={size}
                validateRequiredKeys={validateRequiredKeys}
                placeholder={placeholder}
              />
            );
          case 'multi-checkbox-dropdown':
            return (
              <MultiSelectDropdownControl
                required={required}
                {...sharedProps}
                updateAnswerKey={updateAnswerKey}
                dropdownAnswerKey={options[0].label}
                options={options}
                dropdownValue={dropdownValue}
                setDropdownKey={setDropdownKey}
                validateRequiredKeys={validateRequiredKeys}
                setNavNextVisibility={setNavNextVisibility}
                text={i18next.t(text)}
                desc={desc}
                dropdown_text={i18next.t(dropdown_text)}
              />
            );
          case 'slider':
            return (
              <SliderControl
                {...sharedProps}
                defaultValue={defaultValue}
                help_image_url={help_image_url}
                max={max}
                min={min}
                required={required}
                setNavNextVisibility={setNavNextVisibility}
                sliderIndicatorText={desc}
                validateRequiredKeys={validateRequiredKeys}
              />
            );
          case 'checkbox':
            return (
              <CheckboxControl
                required={required}
                {...sharedProps}
                updateAnswerKey={updateAnswerKey}
                validateRequiredKeys={validateRequiredKeys}
                text={i18next.t(text)}
                desc={desc}
              />
            );

          default:
            return <div key={key}>Controls for {this.props.questiontype} does not exist</div>;
        }
      });
    }

    return null;
  };

  handleStepnav = () => {
    const { currentStep, navNextVisible } = this.props;
    return (
      <StepNav
        handlePrev={currentStep !== 0 && currentStep !== 1 ? this.handlePrev : undefined} //Hide if 1st question
        handleNext={navNextVisible && this.handleNext}
        disableNext={
          this.props.data.controls[0].type === 'multi-counter' &&
          this.state.total < this.props.data.controls[0].options[0].max
        }
      />
    );
  };

  render() {
    const { id, title, icon, text } = this.props.data;
    const title_key = i18next.t(title);
    const text_key = i18next.t(text);
    return (
      <Styled.Wraper ref={this.questionRef}>
        <Styled.Container id={id}>
          <Typography size="3" text={title_key} />
          {/* <Styled.Title>{title}</Styled.Title> */}
          {/* <Typography size="2" text={text} /> */}
          <Styled.Question style={{ fontSize: '16px' }}>{text_key}</Styled.Question>
          <Styled.Icon>{icon && <Icon icon={icon} size={150} />}</Styled.Icon>
          <div style={{ margin: 'auto', width: '100%' }}>{this.controllsHandler()}</div>
          {this.handleStepnav()}
        </Styled.Container>
      </Styled.Wraper>
    );
  }
}

export default Question;
